.team-productivity-tabs {
    .tab-line {
        border-radius: 8px 8px 0 0;
    }

    .nav-link {
        cursor: pointer;
    }

    .team-productivity-sub-tabs {
        .tab-bg {
            .tab-list {
                margin: 0px;
                padding: 30px 20px;
                background: $white;
                border-radius: 0 0 8px 8px;
                margin-bottom: 30px;
            }
        }
    }
}

.productivity-seletcted-checkbox-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .multiselect-dropDown {
        width: 155px;

        .css-1r4vtzz {
            background: $white;
        }
    }

}

.checkbox-filter-left {
    display: flex;
    align-items: center;

    .custom-control-label {
        padding-left: 25px;
    }

    .custom-checkbox {
        margin-right: 20px;
    }
}

.productivity-report-table {
    margin-top: 30px;
    border-radius: 8px;
    background: $white;
    width: 100%;
    overflow: auto;
    max-height: 510px;

    table {
        border-radius: 8px;
        table-layout: inherit;

        tr {}

        th {
            vertical-align: top;
            padding-bottom: 0px;

            .sub-table-data {
                margin-top: 8px;
            }
        }

        th,
        td {
            &:first-child {
                padding-left: 20px;
            }

        }

        td {
            border-right: 1px solid rgba(0, 0, 0, .1) !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.multi-column-data {
                border-right: 0px !important;
            }
        }
    }

    .multi-column-data {
        width: 200px;
        padding: 0px;

        span {
            height: 30px;
            white-space: normal;
            display: inherit;
            vertical-align: top;
            padding: 15px 15px 0;
            white-space: normal;
        }

        &.multi-column-width {
            width: 300px;
        }

    }

    .sub-table-data {

        tr {
            border-bottom: 0px;
            border-right: 1px solid rgba(0, 0, 0, .1) !important;

            th {
                &:first-child {}

                vertical-align: top;
                white-space: nowrap;
                padding-left: 15px;
                border-radius: 0;
                background: rgba($white, 0.4);
                padding-bottom: 10px;
                position: inherit;

            }

            td {
                width: 100px;
                padding-left: 15px !important;
                border-right: 0px;
                border-right: 0px !important;
            }
        }
    }

    .rbh-txt {
        max-width: 171px;
    }

    .am-txt-value {
        max-width: 171px;
    }

    .bm-txt-value {
        max-width: 150px;

    }

    .aro-txt-value {
        max-width: 150px;

    }

    .bro-agent-txt-value {
        max-width: 150px;

    }

    .date-txt-value {
        width: 100px;
    }

    .total-disb-txt-value {
        white-space: normal;
        width: 70px;
    }

    .zm-txt {
        max-width: 150px;

    }

    .arco-txt {
        max-width: 150px;

    }

    tfoot {
        position: sticky;
        bottom: 0;
        background: $white;

        tr {
            td {
                padding: 13px 9px;
                font-weight: 500;
                font-size: 12px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.8);
                text-align: left;
            }
        }
    }

    thead {
        position: sticky;
        top: 0;
    }
}

.team-productivity-filter {
    fieldset {
        width: 13%;
    }

    .dateragefiled {
        width: 240px;
    }
}


.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;

    .modal-main {
        position: fixed;
        background: white;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close_icn {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 21px;
        cursor: pointer;
    }

    .modal-body {
        max-height: 450px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }
}

.overflow-hidden {
    overflow: hidden;
}



.model-popup-outer {
    .modal-body {
        max-height: 470px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }


}



.bro-agent-popup {
    .modal-main {
        width: 1160px;
        max-width: 100%;
    }

    .bro-agent-list-outer {
        overflow-x: auto;
        width: 100%;

        .bro-agent-selected-list {
            padding-left: 20px;
            padding-right: 15px;
            padding-top: 20px;



            ul {
                li {
                    padding-bottom: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            h2 {
                padding-bottom: 20px;
                padding-right: 30px;
            }

            .selected-list-count {
                font-size: 12px;
                font-weight: normal;
                padding-left: 5px;
            }
        }

        table {
            table-layout: inherit;

            th {
                max-width: 220px;

                &:first-child {
                    padding-left: 20px;
                    border-radius: 6px 0 0 0;

                }

                &:last-child {
                    padding-right: 20px;
                    border-radius: 0 6px 0 0;
                }

                .custom-control-label {
                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: rgba($heading-color, 0.54);
                        padding-left: 5px;
                    }
                }
            }

            td {
                max-width: 220px;
                padding: 0px;
                vertical-align: top;
                border-right: 1px solid $border-color;
                white-space: nowrap;
                padding-right: 0px;

                &:first-child {
                    padding-left: 0px;
                    border-left: 0px;
                }

                &:last-child {
                    padding-right: 0px;
                    border-right: 0px;
                }
            }

            tbody {
                tr {
                    border: 1px solid $border-color;
                    border-top: 0px;
                }
            }
        }
    }

    .select-agent-list-main {
        display: flex;
        width: 100%;


        .accordion-body {
            padding-left: 20px;
            max-height: 170px;
            overflow-y: auto;
        }

        .custom-control-label {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #222222;

            &::before {
                height: 14px;
                width: 14px;
            }

            &::after {
                left: -1px;
                top: 0px;
            }
        }

        .custom-control-input {
            height: 18px;
            width: 18px;
        }

        .accordion-header {
            .custom-checkbox {
                .custom-control-input:checked~.custom-control-label::after {
                    content: "\e90c";
                    font-size: 16px !important;
                }
            }

            .custom-control-input {
                width: 100%;
            }

            .custom-control {
                width: 100%;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

    }

    .accordian-data {
        .accordion-header {
            .accordion-button {
                &:after {
                    content: "\e917";
                    right: 0px;
                }

                line-height: 18px;
            }

            .accordion-button:not(.collapsed):after {
                content: "\e91a";
                right: 0px;
            }
        }

        .accordion-item {
            margin-bottom: 0px;
        }
    }

    .modal-footer {
        margin: 25px 0;
        float: right;
    }

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
        padding-top: 20px;

        .search-bx {
            width: 300px;
        }

        h2 {
            padding-bottom: 0px;
        }
    }

}

.productivity-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #ccc;
    border-color: #ccc;
}



.more-filter {
    // display: flex;
    // align-items: center;
    // color: $heading-color;
    margin: 0px 0px 0px 10px;

    .more-filter-txt,
    .leass-filter-txt {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-left: 5px;
        white-space: nowrap;
        margin-right: 20px;
    }

    .dropdown {
        display: flex;
        position: relative;

        .dropdown-toggle {
            display: flex;
            align-items: center;
            background: transparent;
            border: 0px;

            &::before {
                content: "\e907";
                font-family: dc !important;
            }
        }

        .leass-filter-txt {
            display: none;
        }

        .dropdown-menu {
            box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
            border-radius: 6px;
            width: 320px;
            padding: 30px;
            background: $white;
            // margin-top: 33px;
            z-index: 99;
        }

        &.show {
            .dropdown-menu {
                display: block;
                position: absolute !important;
                // right: 0px !important;
                top: 18px !important;

            }

            .dropdown-toggle {
                &::before {
                    content: "\e90c";
                    font-family: dc !important;
                }
            }

            .leass-filter-txt {
                display: block;
            }

            .more-filter-txt {
                display: none;
            }
        }
    }

    .more-filter-option {
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $heading-color;
            display: block;
            margin-bottom: 16px;
        }

        .filter-form {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            fieldset {
                width: 100%;
                margin-right: 0px;
            }
        }
    }

    .dropdown.show {
        .dropdown-menu {
            transform: translate(-33%, 45px) !important;
            inset: initial !important;
        }
    }

    fieldset {
        margin-bottom: 20px;
    }

    .btn-submit-reset {
        &.more-filter-btn {
            margin-top: 0px;
        }
    }
}

.btn-submit-reset {
    .show-report-btn {
        width: 120px;
        padding: 0px 10px;
    }
}

.searching-list {
    background: $white;
    // border: 1px solid $border-color;
    padding: 20px;
    position: absolute;
    z-index: 9;
    width: 100%;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;
    max-height: 220px;
    overflow-y: auto;

    ul {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 13px;

            &:last-child {
                padding-bottom: 0px;
            }
        }

        .label-txt {
            font-weight: 600;
            font-style: italic;
        }

        .custom-control {
            width: 70%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

}

.required-msg {
    border: 1px solid red !important;
    border-radius: 8px;
}

.stage-text {
    text-overflow: ellipsis;
    white-space: nowrap !important;
}

.manage-team-view-popup {
    &.model-popup-outer {
        .modal-body {
            max-height: 370px;
        }
    }
}

.pending-report-heading-right-panel {
    margin-left: 1115px;
}

.erroe-txt {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #ff2e2e;
    display: block;
    margin-top: 2px;
    margin-bottom: -8px;
}

.payment-pending-report-table {
    &.productivity-report-table {

        .sub-table-data {
            tr {
border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
                td {
                    width: 214px;
                }
            }
        }
        .multi-column-data.multi-column-width{
            width: 214px;
            span{
                white-space: nowrap;
            }
        }
    }
}

.search-manage-btn{
align-items: center;
justify-content: space-between;
margin-top: 20px;
}

.search-opt-btn {
    display: flex;
    align-items: center;
}

.loan-view-select-list-filter{
    ul{
        display: flex;
        align-items: center;
        margin-top: 20px;
        li{
            margin-right: 20px;
            label{
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
            }
            span{
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                display: block;
            }
        }
    }
}

.loan-detail-top-filter{
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
}

.loan-detail-view-table{
    &.productivity-report-table{
        .multi-column-data{
            width: 150px;
            span{
                white-space: nowrap;
            }
        }
        .sub-table-data {
            tr{
                 td{
                    width: 150px;
                 }
                }
            }
                    
    } 
}

.report-data-table{
    table{
        thead{
            tr{
                th{
                    div{
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.pending-payment-lead-filter{
    .multiselect-dropDown{
     .css-1r4vtzz,.css-48ayfv{
     padding-left: 8px !important;
     max-width: 97% !important;
    }
}
.datepicker{
     input{
        padding-left: 8px !important;
     }
}
.dateragefiled{
    width: 280px;
}

}


.manage-table-filter{
    .single-select{
        width: 160px;
    }
    .multiselect-dropDown{
        width: 160px;
        .css-1r4vtzz,  .css-48ayfv{
            background-color: #fff;
        }
    }
   
}


@media (max-width: 991px){

    .manage-table-filter{
        margin-top: 20px;
        .single-select{
            width: 170px;
        }
        .multiselect-dropDown{
            width: 220px;
        }
        
    }
}