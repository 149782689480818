.top-heading {
    padding: 18px 0;
    display: block;

    h1 {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: $heading-color;
    }
}

.loan-filter-bg {
    background: $white;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px;
    // margin-bottom: 20px;

    .lead-list-filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .flex-column {
        .tab-list {
            display: flex;
            align-items: center;
            padding: 25px 20px 18px;
            background: $white;
            border-radius: 8px 8px 0 0;

            &:after {
                display: none;
            }
        }
    }

    .css-1pcexqc-container {
        input {
            appearance: auto;
            -webkit-appearance: auto;
            -moz-appearance: auto;
        }
    }

    fieldset {
        margin-right: 10px;
        margin-bottom: 0px;
        width: 11%;
    }

    .dateragefiled {
        width: 18%;
    }


}

.search-bx {
    .material {
        .form-input {
            padding-left: 45px;
        }

        i {
            position: absolute;
            left: 15px;
            font-size: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.multiselect-dropDown {

    .css-1r4vtzz,
    .css-48ayfv {
        height: 42px;
        border: 1px solid #bbbbbb !important;
        border-radius: 8px !important;
        background: transparent;
        width: 100%;
        box-shadow: none;
        padding-left: 15px;
    }

    .css-1jllj6i-control {
        // width: 100%;

        box-shadow: none;
    }

    .css-6y1x9t-control {
        border-color: hsl(0, 0%, 80%) !important;
        box-shadow: none;

        &:hover {
            border-color: hsl(0, 0%, 80%) !important;
            box-shadow: none;
        }
    }

    .css-1v99tuv,
    .css-11unzgr {
        font-size: 12px;
        color: $heading-color;
    }

    input {
        appearance: auto;
    }

    .css-1qprcsu-option {
        font-weight: normal;
    }

    .css-1gpjby2 {
        svg {
            display: none;
        }

        &:after {
            content: "\e917";
            position: absolute;
            right: 5px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .css-1v99tuv {
        position: relative;
        top: -1px;
    }

    .css-48ayfv+div {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 4px 11px rgba(0, 0, 0, 0.1);
    }
}

.btn-submit-reset {
    display: flex;
    align-items: center;

    .btn-primary {
        min-width: auto;
        margin-right: 10px;
        padding: 0px 20px;
    }

    .btn-reset {
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        border: 0px;
        color: $heading-color;
    }

    &.more-filter-btn {
        margin-top: 20px;

        .btn-primary {
            min-width: 171px;
            margin-right: 10px;
        }
    }
}

.datepicker {
    input {
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 11px 15px;
        font-weight: 400;
        font-size: 12px;
        background: url(../../images/date.svg) no-repeat right 7px center;
    }

    .react-datepicker__header__dropdown {
        padding: 10px;

        select {
            appearance: auto;
            background: transparent;
            border: 0px;
        }
    }
}

.timepicker-filed {
    input {
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 11px 15px;
        font-weight: 400;
        font-size: 12px;
        background: url(../../images/time_icn.svg) no-repeat right 7px center;
    }

    .react-datepicker__header__dropdown {
        padding: 10px;

        select {
            appearance: auto;
            background: transparent;
            border: 0px;
        }
    }
}

.timepicker-filed {

    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
        left: -18px !important;
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #0bbddd !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #0bbddd !important;
}

.filter-form {
    .filter-heading {
        margin-left: 0px;
        padding: 20px 0 10px;
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $heading-color;
        width: 100%;
    }

    fieldset {
        width: 175px;
        margin-right: 8px;
    }

    .Date-picker-sec {
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .follow-up-sec {
        margin-top: 0px;
    }

    .css-g1d714-ValueContainer {
        padding-left: 14px;
    }

    .css-1jllj6i-control {
        min-width: auto !important;
    }

    .css-1vr111p-option {
        min-width: auto !important;
    }

    .css-48ayfv+div {
        width: 100%;
    }

    .css-6y1x9t-control,
    .css-1qprcsu-option {
        min-width: auto !important;
    }
}


.single-select {
    .react-select__placeholder {
        top:50%;
        font-size: 13px;
        color: rgba($heading-color, 0.6) !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);

        margin-right: 2px;
        position: absolute;
        transform: translateY(-50%);
        box-sizing: border-box;
    }

    .react-select__indicators {
        svg {
            display: none;
        }
    }

    .react-select__indicator-separator,
    .css-109onse-indicatorSeparator {
        display: none;
    }

    .react-select__control--menu-is-open,
    .react-select__control {
        height: 42px;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none;
        border-color: #bbbbbb !important;

        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }

    .react-select__indicators {
        &:after {
            content: "\e917";
            position: absolute;
            right: 3px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .react-select__single-value,
    .css-1v99tuv {
        font-size: 12px;
        font-weight: 500;
        color: $heading-color;
    }

    .react-select__menu-list,
    .css-11unzgr {
        font-size: 12px;
        color: $heading-color;
    }

    .css-1qprcsu-option,
    .css-1vr111p-option {
        font-weight: normal;

        input {
            position: relative;
            top: 3px;
        }
    }

    .react-select__single-value {
        max-width: calc(100% - 20px) !important;
    }
}

.show.dropdown .top-btn-none {
    opacity: 0;
}

.search-filter-result-count {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $heading-color;
    display: block;
    width: 100%;
    padding: 20px 20px 10px;
    background: #eff2f9;
}

.filter-filed-form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .search-bx {
        input {
            height: 42px !important;
        }
    }
}

.selected-chips {
    ul {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        padding: 10px 0;

        li {
            background: $white;
            padding: 6px 6px 6px 17px;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: $heading-color;
            display: flex;
            align-items: center;
            border-radius: 16px;
            margin-right: 8px;
            border: 1px solid #dde1e9;
            margin-bottom: 8px;

            span {
                background: rgba(227, 231, 241, 0.6);
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 9px;
                border-radius: 12px;
            }

            &.clear-txt {
                background: none;
                color: $accent-color;
                border: 0px;
            }
        }
    }
}


.multiselect-dropDown .css-48ayfv+div {
    width: 100%;
}


.multiselect-dropDown .css-1jllj6i-control,
.multiselect-dropDown .css-1qprcsu-option,
.multiselect-dropDown .css-1vr111p-option,
.multiselect-dropDown .css-6y1x9t-control {
    min-width: auto;
}

.multiselect-dropDown .css-1jllj6i-control {
    box-shadow: none;
}



.tab-line {
    .tab-list {
        display: flex;

        .nav-item {
            .nav-link {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);
                border-bottom: 2px solid transparent;
                padding-bottom: 15px;
                cursor: pointer;

                &.active {
                    font-weight: 500;
                    color: $accent-color;
                    border-color: $accent-color;
                }

            }
        }
    }
}



.sub-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.report-heading-right-panel {
    display: flex;
    align-items: center;

    .report-heading {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        margin-right: 0px;
    }

    .report-tab {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        display: flex;
        padding: 0px 0px;
        margin-left: 10px;

        span {
            padding: 7px 6px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;

            i {
                color: $heading-color;
                font-weight: 600;
                font-size: 12px;
            }

            &.active {
                background: #0BBDDD;
                color: $white;

                i {
                    color: $white;
                }
            }

            &:first-child {
                padding-left: 10px;

                &.active {
                    border-radius: 5px 0 0 5px;
                }

            }

            &:last-child {
                padding-right: 10px;

                &.active {
                    border-radius: 0px 5px 5px 0px;
                }

            }
        }

    }

    .btn-export {
        margin-left: 10px;
    }

}

.financer-tabs {
    .tab-line {
        background: $white;
        border-radius: 8px;
        padding: 15px 20px;

        .nav-item {
            margin-right: 40px;
        }
    }

    .tab-bg {
        .tab-list {
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 30px;

            .nav-item {
                margin-right: 15px;

                .nav-link {
                    background: $white;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    border-radius: 100px;
                    color: $heading-color;
                    padding: 8px 20px;

                    &.active {
                        background: $accent-color;
                        color: $white;
                    }

                }
            }
        }
    }
}

.accordian-data {
    .collapse {
        display: none;

        &.show {
            display: block;
        }
    }

    .accordion-item {
        background: $white;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .accordion-header {
        padding: 10px 20px;
        position: relative;

        .accordion-button {
            background: transparent;
            border: 0px;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $heading-color;
             display: flex;
             align-items: center;
             justify-content: space-between;
             width: 100%;

            &:after {
                content: "\e907";
                background-repeat: no-repeat;
                transition: transform .2s ease-in-out;
                font-family: dc;
                position: absolute;
                right: 20px;
                position: absolute;
                right: 20px;
                font-size: 25px;

            }
            .btn-line{
                min-width: auto;
                padding: 0px 15px;
                margin-right: 50px;
                line-height: 40px;
                i{
                     margin-right: 5px;
                }
            }
        }

        .accordion-button:not(.collapsed):after {
            content: "\e90c";
        }
    }
}


.sales-report-data-table {
    width: 100%;
    overflow: auto;

    table {
        thead {
            tr {
                th {
                    white-space: normal;
                    width: 110px;
                    vertical-align: top;

                    &:first-child {
                        padding-left: 15px;
                    }

                }
            }
        }

        tbody {
            tr {
                td {
                    width: 10%;
                    &:first-child {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}
.chart-report-outer{
    background: $white;
    padding: 20px;
}

.no-record-text {
    text-align: center;
    padding: 15px 0;
    color: #000;
    font-size: 16px;
}